/* EventsSection.css */
.events-section {
  padding: 20px;
  text-align: center;
  margin-left: 120px;
  margin-right: 120px;
}

.events-section h1 {
  font-size: 40px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.events-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding-bottom: 20px;
  gap: 20px; /* Gap between event cards */
}

.event-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  flex: none;
  text-align: center;
}

.event-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.event-info {
  margin-top: 15px;
}

.event-title {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}

.event-description {
  font-size: 14px;
  color: #666;
}

.view-all-btn {
  margin-top: 40px;
  background-color: #ff5c57;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.view-all-btn:hover {
  background-color: #db5651;
}
