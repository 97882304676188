.ieee-roles {
  max-width: 1125px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.ieee-roles h2 {
  margin-top: 40px;
  margin-bottom: 40px;
  color: #000000;
  font-size: 2em;
  font-weight: bold;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 15px;
  border: 1px solid #ddd;
}

th {
  background-color: #ff5c57;
  color: white;
  font-weight: bold;
}

td {
  background-color: #f9f9f9;
  color: #333;
}

tr:hover {
  background-color: #f1f1f1;
}

/* Responsive styles for tablets and smaller screens (768px and below) */
@media only screen and (max-width: 768px) {
  .ieee-roles {
      padding: 15px;
  }

  .ieee-roles h2 {
      font-size: 1.8em;
      margin-top: 30px;
      margin-bottom: 20px;
  }

  table {
      font-size: 14px;
  }
}

/* Responsive styles for mobile devices (480px and below) */
@media only screen and (max-width: 480px) {
  .ieee-roles {
      padding: 10px;
  }

  .ieee-roles h2 {
      font-size: 1.6em;
      margin-top: 20px;
      margin-bottom: 15px;
  }

  table {
      font-size: 12px;
  }
}
