/* Base styles for all devices */
.resource-accordion {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.resource-accordion:hover {
  background-color: #f1f1f1;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header h3 {
  font-size: 1.5em;
}

.accordion-content {
  margin-top: 15px;
}

.accordion-content p {
  margin-bottom: 10px;
}

.accordion-content a {
  color: #0073e6;
  text-decoration: none;
  font-weight: bold;
}

.accordion-content a:hover {
  text-decoration: underline;
}

/* Responsive styles for screens 768px and below (tablets and smaller) */
@media only screen and (max-width: 768px) {
  .resource-accordion {
      padding: 10px;
  }

  .accordion-header h3 {
      font-size: 1.3em;
  }

  .accordion-content p {
      font-size: 0.95em;
  }
}

/* Responsive styles for screens 480px and below (mobile devices) */
@media only screen and (max-width: 480px) {
  .resource-accordion {
      padding: 8px;
  }

  .accordion-header h3 {
      font-size: 1.2em;
  }

  .accordion-content {
      margin-top: 10px;
  }

  .accordion-content p {
      font-size: 0.9em;
      margin-bottom: 8px;
  }

  .accordion-content a {
      font-size: 0.95em;
  }
}
