.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  transition: background-color 0.3s ease;
  height: 80px;
  background-color: transparent;
}

.header.scrolled {
  background-color: #185086;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.logo {
  display: flex;
  align-items: center;
  margin-left: 140px;
  font-size: 54px;
  font-weight: bold;
  color: white;
}

.logo img {
  height: 100px; 
  margin-right: 10px;
  width: 100px;
  margin-top: 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #7c8083; 
  min-width: 260px; 
  z-index: 1000;
}

.dropdown-content button {
  padding: 10px; 
  color: rgb(0, 0, 0);
  text-align: left; 
  background: none; 
  border: none; 
  cursor: pointer; 
}



.nav {
  margin-right: 100px;
  margin-left: 450px;
}

.nav button {
  margin: 0 10px;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.nav button:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .logo {
    margin-left: 20px;
    font-size: 20px;
  }

  .nav {
    margin-right: 20px;
    margin-left: auto; 
  }

  .nav button {
    font-size: 14px;
  }

  .header {
    height: 70px;
    padding: 5px 0;
  }
}

@media only screen and (max-width: 480px) {
  .logo {
    margin-left: 10px;
    font-size: 18px;
  }

  .nav {
    margin-right: 10px;
  }

  .nav button {
    font-size: 12px;
    margin: 0 5px;
  }

  .header {
    height: 60px;
    padding: 5px 0;
  }
}
