.footer {
    background-color: #111;
    color: #ccc;
    padding: 30px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align all sections to the left */
    justify-content: flex-start; /* Ensure all sections start at the top */
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    margin-bottom: 20px;
    text-align: left;
    padding: 0 20px;
    min-width: 250px; /* Set a minimum width to align columns */
}

.footer-section h2 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 15px;
    letter-spacing: 1px;
    text-align: left; /* Ensure headings align to the left */
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
}

.footer-section p, .footer-section ul {
    font-size: 14px;
    color: #ccc;
    line-height: 1.7;
    letter-spacing: 0.5px;
    text-align: left; /* Ensure text is aligned left in all sections */
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 12px;
}

.footer-section ul li strong {
    display: inline-block;
    min-width: 110px; /* Align strong text to the left */
}

.footer-section.map {
    text-align: center;
    margin-top: 20px;
}

.footer-section.map iframe {
    border: none;
    width: 100%;
    max-width: 400px;
    height: 250px;
}

.footer-bottom {
    text-align: center;
    padding: 20px;
    background-color: #000;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-top: 20px;
    line-height: 1.8;
}

.social-icons {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social-icons a {
    color: #ccc;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #fff;
}

.social-icons i {
    font-size: 24px;
}


/* Media Queries for larger screens */
@media (min-width: 768px) {
    .footer-content {
        flex-direction: row;
        align-items: flex-start; /* Align sections vertically at the top */
        justify-content: space-between;
    }

    .footer-section {
        flex: 1;
        margin-bottom: 0;
        text-align: left;
    }
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .footer-section {
        text-align: center;
    }

    .footer-section ul li strong {
        display: block;
        min-width: unset;
    }

    .footer-section h2 {
        font-size: 18px;
        padding-bottom: 8px;
    }
}
