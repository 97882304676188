/* Base styles for all devices */
.testimonial-container {
    text-align: center;
    margin-top: 40px;
    padding: 40px 20px;
    background-color: #f9f9f9; /* Slightly grey background for better contrast */
    border-radius: 10px; /* Rounded corners */
    margin-left: 300px;
    margin-right: 300px;
}

.testimonial-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333; /* Darker color for better readability */
}

.testimonial-subtitle {
    font-size: 1.2rem;
    color: #777;
    margin-bottom: 35px;
}

.testimonial-avatars {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.slick-prev, .slick-next {
    z-index: 1;
    font-size: 1.5rem;
    color: #333;
}

.testimonial-avatar {
    width: 200px; /* Set a fixed width */
    height: 180px; /* Set a fixed height to make it a perfect circle */
    border-radius: 50%; /* This makes the image circular */
    opacity: 0.6;
    transition: opacity 0.3s, transform 0.3s;
    cursor: pointer;
    border: 2px solid transparent;
    object-fit: cover; /* Ensure the image covers the area without distortion */
}

.testimonial-avatar:hover {
    opacity: 1;
    transform: scale(1.15); /* Slight zoom effect */
    border: 2px solid #e75651; /* Highlight the border on hover */
}

.highlighted-avatar {
    border: 4px solid #ff5c57;
    opacity: 1;
    transform: scale(1.2); /* Extra emphasis on selected avatar */
}

.testimonial-details {
    margin-top: 30px;
}

.testimonial-name {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #444;
}

.testimonial-role {
    font-size: 1.1rem;
    color: #777;
    margin-bottom: 25px;
}

.testimonial-text {
    font-size: 1.2rem;
    color: #555;
    max-width: 650px;
    margin: 0 auto;
    font-style: italic;
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f0f0; /* Subtle background for testimonial text */
}

button.past-members-btn {
    margin-top: 20px;
    background-color: #ff5c57;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

button.past-members-btn:hover {
    background-color: #ed5651;
}

/* Responsive styles for screens 768px and below (tablets and smaller) */
@media only screen and (max-width: 768px) {
    .testimonial-container {
        margin-left: 50px;
        margin-right: 50px;
    }

    .testimonial-title {
        font-size: 2rem;
    }

    .testimonial-avatar {
        width: 100px;
        height: 100px;
    }

    .testimonial-name {
        font-size: 1.5rem;
    }

    .testimonial-text {
        font-size: 1rem;
        max-width: 100%;
    }
}

/* Responsive styles for screens 480px and below (mobile devices) */
@media only screen and (max-width: 480px) {
    .testimonial-container {
        margin-left: 20px;
        margin-right: 20px;
        padding: 20px;
    }

    .testimonial-title {
        font-size: 1.8rem;
    }

    .testimonial-subtitle {
        font-size: 1rem;
    }

    .testimonial-avatar {
        width: 80px;
        height: 80px;
    }

    .testimonial-name {
        font-size: 1.2rem;
    }

    .testimonial-text {
        font-size: 0.9rem;
        max-width: 100%;
    }

    button.past-members-btn {
        padding: 8px 16px;
        font-size: 14px;
    }
}
