/* Base styles for all devices */
.membership-info {
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center;
  transition: transform 0.3s ease; /* Smooth hover effect */
  margin: 20px;
}

.membership-info:hover {
  transform: scale(1.02); /* Scale effect on hover */
}

.membership-info h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333; /* Darker text for better readability */
  font-weight: 600; /* Bold weight for emphasis */
  position: relative; /* Position for underline */
}

.membership-info h2::after {
  content: '';
  display: block;
  width: 50px; /* Width of underline */
  height: 4px; /* Height of underline */
  background: #ff6347; /* Accent color for underline */
  margin: 10px auto; /* Center underline */
}

.membership-info p {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555; /* Slightly lighter than heading */
  line-height: 1.5; /* Improved line spacing */
}

.membership-info ul {
  list-style-type: none;
  padding: 0;
}

.membership-info ul li {
  font-size: 1.1em;
  margin: 15px 0; /* More spacing between items */
  color: #333; /* Dark color for items */
  transition: color 0.3s ease; /* Smooth color change on hover */
}

.membership-info ul li:hover {
  color: #ff6347; /* Change color on hover */
}

/* Responsive styles for screens 768px and below (tablets and smaller) */
@media only screen and (max-width: 768px) {
  .membership-info {
      padding: 30px;
  }

  .membership-info h2 {
      font-size: 2.2em;
  }

  .membership-info p, .membership-info ul li {
      font-size: 1.1em;
  }
}

/* Responsive styles for screens 480px and below (mobile devices) */
@media only screen and (max-width: 480px) {
  .membership-info {
      padding: 20px;
  }

  .membership-info h2 {
      font-size: 1.8em;
      margin-bottom: 15px;
  }

  .membership-info p, .membership-info ul li {
      font-size: 1em;
  }

  .membership-info ul li {
      margin: 10px 0;
  }
}
