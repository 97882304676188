/* AchievementsAlbum.css */

.achievements-album {
    text-align: center;
    padding: 20px;
  }
  
  .album-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    position: relative;
  }
  
  .album-title::after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: red;
    display: block;
    margin: 10px auto 0;
  }
  
  .album-slide {
    display: flex;
    justify-content: center;
  }
  
  .album-image {
    max-width: 100%;
    max-height: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    margin-left: 460px;
  }
  
  .album-image:hover {
    transform: scale(1.05);
  }
  