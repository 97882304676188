/* Base styles for all devices */
.resource-library {
  padding: 20px;
  max-width: 1150px;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 40px;
}

.resource-library h2 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 40px;
}

.search-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
}

.search-bar {
  width: 100%;
  padding: 10px 20px 10px 40px; /* Add padding to the left to make space for the icon */
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1.2em;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
  font-size: 1.2em;
}

.resource-list {
  margin-top: 20px; 
}


/* Responsive styles for screens 768px and below (tablets and smaller) */
@media only screen and (max-width: 768px) {
  .resource-library {
      padding: 15px;
      max-width: 100%;
  }

  .resource-library h2 {
      font-size: 1.8em;
  }

  .search-bar {
      font-size: 1.1em;
      padding: 10px 15px 10px 35px;
  }

  .search-icon {
      font-size: 1.1em;
  }
}

/* Responsive styles for screens 480px and below (mobile devices) */
@media only screen and (max-width: 480px) {
  .resource-library {
      padding: 10px;
  }

  .resource-library h2 {
      font-size: 1.6em;
      margin-bottom: 15px;
  }

  .search-bar {
      font-size: 1em;
      padding: 8px 10px 8px 30px;
  }

  .search-icon {
      font-size: 1em;
  }

  .resource-list {
      margin-top: 15px;
  }
}
