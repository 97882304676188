.topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: #fff;
  text-align: center;
  position: relative;
  height: auto; /* Change to auto for better mobile support */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.logo-container {
  width: 15%; /* You may want to adjust this */
}

.topnav-logo {
  max-width: 100%;
  height: auto;
}

.topnav-text {
  width: 70%;
  text-align: center;
  color: #333;
}

.topnav-left-logo {
  height: 125px;
  margin: 22px 100px 0; /* Combined margin properties */
}

.topnav-right-logo {
  height: 160px;
  margin: 22px -100px 0; /* Combined margin properties */
}

.topnav-text {
  margin-top: 40px;
}

.topnav-text h1 {
  font-size: 1.3rem;
  font-weight: bold;
  color: #8b0000; /* Dark red color */
}

.topnav-text h2 {
  font-size: 1.1rem;
  font-weight: normal;
  color: #333;
}

.topnav-text h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0c2743; /* Dark blue color */
}

.carousel-button {
  display: inline-block;
  padding: 3px 10px;
  background-color: #185086;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  height: 30px;
  margin-top: 5px;
}

.topnav-text h4 {
  font-size: 1.2rem;
  color: #333;
  margin-top: -10px;
}

@media screen and (max-width: 768px) {
  .topnav {
    flex-direction: column; /* Stack elements vertically */
    height: auto; /* Ensure auto height for flexibility */
    padding: 10px 0; /* Adjust padding for smaller screens */
  }

  .logo-container {
    width: 30%; /* Adjust width for smaller screens */
    margin-bottom: 10px; /* Space between logos */
  }

  .topnav-text {
    width: 100%; /* Full width on mobile */
  }

  .topnav-left-logo,
  .topnav-right-logo {
    height: 80px; /* Smaller logo height on mobile */
    margin: 10px 0; /* Reduce margin */
  }

  .topnav-text h1 {
    font-size: 1.2rem; /* Adjust font size for mobile */
  }

  .topnav-text h2,
  .topnav-text h4 {
    font-size: 1rem; /* Adjust font size for mobile */
  }

  .topnav-text h3 {
    font-size: 1.3rem; /* Adjust font size for mobile */
  }
}

@media screen and (max-width: 480px) {
  .topnav-text h1 {
    font-size: 1rem; /* Further reduce size for very small screens */
  }

  .topnav-text h2,
  .topnav-text h4 {
    font-size: 0.9rem; /* Further reduce size for very small screens */
  }

  .topnav-text h3 {
    font-size: 1.2rem; /* Further reduce size for very small screens */
  }

  .carousel-button {
    padding: 2px 8px; /* Adjust button size for mobile */
    font-size: 0.9rem; /* Smaller button text */
  }
}
