.intro-section {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin: 20px 0;
}

.intro-section h1 {
    font-size: 2.5em;
    font-weight: 200;
}

.intro-section h2 {
    font-size: 2em;
    color: #ff5c57;
    margin-bottom: 20px;
}

.intro-section p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.6;
}

.feature-row img {
    height: 250px;
    width: 250px;
}


.features-container {
    display: flex; /* Use flexbox to align feature rows */
    justify-content: center; /* Center the feature rows */
    align-items: flex-start; /* Align items at the start */
    flex-wrap: wrap; /* Allow rows to wrap */
    margin: 20px 0; /* Space between the container and other elements */
    margin-left: 50px;
}

.feature-row {
    display: flex; /* Flexbox for each feature row */
    align-items: center; /* Vertically center align the items */
    width: 45%; /* Adjust width to fit two items with space in between */
    margin: 10px; /* Margin for spacing between feature rows */
}

.feature {
    max-width: 300px; /* Limit the width of the feature text */
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: transform 0.2s;
    text-align: left; /* Align text to the left */
    margin-left: 20px; /* Space between image and feature card */
}

.feature:hover {
    transform: scale(1.05); /* Scale effect on hover */
}

.feature h3 {
    color: #ff5c57;
    margin-bottom: 10px;
    text-align: center;
}

.feature p {
    color: #666;
    margin-top: 4px;
    text-align: center;
}

.feature-image {
    width: 120px; /* Set a specific width for the image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Round the image corners */
    margin-right: 20px; /* Space between image and text */
}

.call-to-action {
    font-weight: bold; /* Use bold for better visibility */
    color: #ff5c57;
    margin-top: 30px;
    font-size: 1.3em;
}
