/* Base styles for all devices */
.membership-page {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  text-align: center;
}

.membership-join {
  margin-top: 20px;
}

.join-btn {
  background-color: #185086;
  color: white;
  font-size: 1em;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.join-btn:hover {
  background-color: #154674;
}

a {
  text-decoration: none;
}

/* Responsive styles for screens 768px and below (tablets and smaller) */
@media only screen and (max-width: 768px) {
  .membership-page {
      padding: 15px;
      max-width: 100%;
  }

  .join-btn {
      font-size: 0.95em;
      padding: 8px 12px;
  }
}

/* Responsive styles for screens 480px and below (mobile devices) */
@media only screen and (max-width: 480px) {
  .membership-page {
      padding: 10px;
  }

  .join-btn {
      font-size: 0.85em;
      padding: 6px 10px;
  }
}
