/* Global reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.image-carousel {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
}

.fade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Subtle black */
  transition: opacity 0.5s ease-in-out; /* Slower fade effect */
  opacity: 0;
  z-index: 1;
}

.fade-overlay.fade-in {
  opacity: 1;
}

.fade-overlay.fade-out {
  opacity: 0;
}

.text-overlay {
  position: absolute;
  bottom: 20%;
  left: 10%;
  color: white;
  text-align: left;
  overflow: hidden;
  opacity: 0; /* Initially hidden */
  z-index: 2;
}

.text-overlay.animate {
  opacity: 1; /* Ensure visibility when animation starts */
}

.text-overlay h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  animation: slideUp 2s ease-in-out forwards;
}

.text-overlay h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: slideRight 2s ease-in-out forwards;
}

.text-overlay .carousel-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #185086;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  opacity: 1; /* Ensure visibility */
  animation: slideUp 1s ease-in-out 0.5s forwards;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 3;
}

.carousel-controls button {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.carousel-button:hover {
  background-color: #194975;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive styles for tablets and smaller screens (768px and below) */
@media only screen and (max-width: 768px) {
  .text-overlay h2 {
      font-size: 1.5rem;
  }

  .text-overlay h1 {
      font-size: 2.5rem;
  }

  .text-overlay .carousel-button {
      font-size: 0.9rem;
      padding: 8px 16px;
  }
}

/* Responsive styles for mobile devices (480px and below) */
@media only screen and (max-width: 480px) {
  .text-overlay {
      bottom: 10%;
      left: 5%;
  }

  .text-overlay h2 {
      font-size: 1.2rem;
  }

  .text-overlay h1 {
      font-size: 2rem;
  }

  .text-overlay .carousel-button {
      font-size: 0.8rem;
      padding: 6px 12px;
  }
  
  .carousel-controls button {
      padding: 8px;
  }
}
