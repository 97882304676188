/* Base styles for all devices */
.why-us-section {
  text-align: center;
  padding: 50px;
}

.features {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.feature {
  max-width: 300px;
}

.icon {
  font-size: 50px;
  color: #ff5c57;
  margin-bottom: 15px;
}

h3 {
  color: #ff5c57;
  font-weight: bold;
  margin-bottom: 15px;
}

p {
  color: #666;
  line-height: 1.6;
}

/* Responsive styles for screens 768px and below (tablets and smaller) */
@media only screen and (max-width: 768px) {
  .why-us-section {
    padding: 30px;
  }

  .features {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .feature {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .icon {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}

/* Responsive styles for screens 480px and below (mobile devices) */
@media only screen and (max-width: 480px) {
  .why-us-section {
    padding: 20px;
  }

  .icon {
    font-size: 35px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  .features {
    margin-top: 15px;
  }
}
