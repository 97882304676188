.curtain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .curtain-open {
    pointer-events: none; 
  }
  
  /* Remaining styles remain unchanged */
  
  /* Curtain panel - left side */
  .curtain-left,
  .curtain-right {
    width: 50%;
    height: 100%;
    background: linear-gradient(135deg, #6b0606 30%, #a20707 80%);
    box-shadow: inset -5px 0 10px rgba(0, 0, 0, 0.3);
    background-size: cover;
    transition: transform 20s ease-in-out;
  }
  
  /* Additional shadow to give fabric-like folds */
  .curtain-left {
    border-right: 2px solid rgba(0, 0, 0, 0.3);
  }
  
  /* Curtain panel - right side */
  .curtain-right {
    border-left: 2px solid rgba(0, 0, 0, 0.3);
  }
  
  /* Animation for the curtain to open */
  .curtain-open .curtain-left {
    transform: translateX(-100%);
  }
  
  .curtain-open .curtain-right {
    transform: translateX(100%);
  }
  
  /* Curtain lines to mimic folds */
  .curtain-left::before,
  .curtain-right::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-image: repeating-linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1) 1px,
      transparent 1px,
      transparent 5px
    );
    opacity: 0.2;
  }
  
  /* Message styling */
  .curtain-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-family: 'Georgia', serif;
    z-index: 1;
  }
  
  .curtain-message h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .curtain-message p {
    font-size: 1.2rem;
  }
  